body {
  background-color:#f3f3f3;
}

a {
  color:black;
}

.activity-content-details {
  display: none;;
}

.activity-content-details p {
  font-family: inherit;
  font-size: 1em;
}

.leaflet-container {
  height: 100vh;
  width: 100vw;
}

.activities-list {
  flex-grow: 1; 
  overflow: auto;
}

.marker-icon {
	background-repeat: no-repeat;
	background-position: left center;
	background-size:  88px; /* Adjust size as needed */
	list-style: none; /* Remove default list item styling */
	cursor: pointer; /* Change cursor to pointer on hover */
  margin-top:10px;
  height:180px;
  overflow:hidden;
  background-color:white;
  }
    
  @keyframes hover-effect {
    0%, 100% {
      margin-top: -42px;
    }
    50% {
      margin-top: -58px;
    }
}

.custom-icon.selected {
    animation: hover-effect 2s infinite;
    z-index:1000!important;
}
  
  .custom-icon svg path {
    stroke: white;
    stroke-width: 1px;
    stroke-linejoin: round;
  }

  
.custom-icon .marker-shadow {
  width: 41px;
  height: 37px;
  position: absolute;
  bottom: 13px;
  left: 5px;
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png");
  background-repeat: no-repeat;
}

.activity-thumbnail.mrgStamp::before {
  width:75px;
  height:65px;
  background-image: url('./mrg_logo_small.png');
}

.mrgStamp {
  position: relative;
}

.popupImage {
  position: relative;
  display: inline-block;
}

.mrgStamp:before {
  content: ""; 
  position: absolute;
  top: 5px;
  right: 5px;
  width: 97px;
  height: 85px;
  background-image: url('./mrg_logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1; 
}

@media (min-width: 768px) {
  .activity-menu-toggle {
    display:none;
  }
}

@media (max-width: 768px) {
  .activity-thumbnail.mrgStamp::before {
    width:75px;
    height:66px;
    background-image: url('./mrg_logo_small.png');
    right:10px;
    top:10px;
  }

.map-container.showInfo, .map-container.showInfo .leaflet-container {
    height: 32vh!important;
  }

  .marker-selected {
    height:20vh!important;
  }

  .infoexpanded .leaflet-container {
    height:20vh;
  }

  .expandInfo .activity-content-details  {
    display: block;
    padding: 0 1em;
  }

  .activity-content:before {
    height:30px!important;
  }

  .expandInfo .activity-thumbnail {
    display: block;
    height:130px;
  }

  .expandInfo.marker-icon {
    height: auto!important;
  }

  .activity-menu.showInfo {
    max-height: 68vh;
    /*overflow: hidden;*/
  }

.leaflet-popup {
  display:none;
}

  .activity-thumbnail {
    display: none;
  }

  .marker-icon {
    height:95px;
  }

  .activity-menu {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw!important;
    max-height: 35px; /*22vh;*/
    overflow-y: auto;
    background: #efece7; /*rgba(255,255,255,0.9);*/ /* semi-transparent background */
    z-index: 1000; /* make sure the menu is above other elements */
    transition: max-height 0.3s ease-in-out; /* animate opening/closing */
    z-index: 10000;
  }


  .activity-menu-filter {
    display:none;
  }

  .activity-menu.collapsed {
    max-height: 85vh;
  }

  .activity-menu-toggle {
    display: block;
    bottom: 0;
    right: 0;
    z-index: 1001; /* above the menu */
    width: 100px;
    height: 3px;
    background-color: #767676; /* white line */
    border: none; /* removes the default button styling */
    cursor: pointer; /* change cursor to pointer on hover */
    border-radius: 10px;
    margin: 2px auto 15px;
    padding:3px;
}  


.activities-list {
  height: 67vh!important;
}

}

  .marker-icon img {
    width: 20px;
    padding-top: 4px;    
  }

  
  .activities-list {
    padding:0;
   /*height: 100vh;*/
    overflow-y: scroll;
  }

  .activity-menu {
    box-shadow: -1px -5px 11px 1px rgba(0,0,0,.25098);
    overflow:none;
    width:350px;    
  }

  .activity-menu, .activity-menu > div {
    display:flex;
    flex-direction: column;
    height: 100vh;
  }


  .popupImage {
    max-width:300px;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 1rem;
    margin-bottom: 1em;
  }

  header {
    background-color:black;
    height:60px;
    padding: 20px;
  }

  @font-face {
    font-family: 'Knockout-48Featherweight';
    src: url('Knockout-48Featherweight.656f1cb80736.woff2') format('woff2');
    font-display: swap;
  }

  header a {
    color:#fff;
    font-family: 'Knockout-48Featherweight', sans-serif;
    line-height: 60px;
    font-size: 3.2em;
    text-decoration: none;
  }

  .activity-menu > div {
    padding:15px;
  }

  .leaflet-pane .leaflet-overlay-pane svg path {
    fill:rgb(36, 36, 36); 
    opacity: 1    ;
  }

  .activity-thumbnail {
    height: 63%;
    background-size:cover;
  }


  .activity-content > p {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 2.2em;
    font-family: 'Knockout-48Featherweight', sans-serif;
    padding-top:10px;
  }
  
  .jkpg-custom > button {
    padding:10px;
  }

  .bio {
    fill: url(#pattern-fill) !important;
    fill-opacity: .6;
    stroke-width: 2px;
  }

  .leaflet-popup-content {
    margin: 19px 19px 12px 20px;
  }
